<style>
  .settings-collapse {
    margin-bottom: 1rem;
  }
  .hard-border {
    border-radius: 0 !important;
  }
  @media (max-width: 1024px) {
    .sm-spacer {
      margin-top: .5rem;
    }
  }
  .save-btn.btn-success {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #34c38f;
    }

    100% {
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
  }
</style>
<script>
import {  VclCode } from 'vue-content-loading';
import { HalfCircleSpinner } from 'epic-spinners';
import Multiselect from "vue-multiselect";

import {get_acsrf_token} from "@/methods";
import {mapGetters} from "vuex";
import {Game, OmegaAction, StreamEvent, TriggerAttributes, TriggerCondition} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    VclCode,
    HalfCircleSpinner,
    // eslint-disable-next-line vue/no-unused-components
    Multiselect
  },
  computed: {
    ...mapGetters([
      'getServer'
    ]),
  },
  methods: {
    // Source: https://gist.github.com/jed/982883
    uuid(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,this.uuid)},
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getConfiguration: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/commands`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.configuration = data.configuration;

          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    /* Support features per game */
    supportsKillfeed() {
      // Supports modding or has native method of kill transmission
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsWhitelist() {
      // Supports either native whitelist or emulated whitelist by kicking
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsReservedSlots() {
      // Either native or emulated reserved slot support by kicking
      let games = [];
      return games.includes(this.options.game);
    },
    supportsIPFeatures() {
      // Games that allow the retrieval of plain text player IP
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsSteam() {
      // Steam auth based games
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    supportsGameIntegration() {
      // Steam auth based games
      let games = [Game.DAYZ];
      return games.includes(this.options.game);
    },
    /* ************************** */
    /* Special features for actions */

    actionToString(action) {
      switch(action) {
        case OmegaAction.HELP: return this.$t('omega.actions.help');
        case OmegaAction.RAW: return this.$t('omega.actions.raw');
        case OmegaAction.MESSAGE_PRIVATE: return this.$t('omega.actions.message_private');
        case OmegaAction.MESSAGE_PUBLIC: return this.$t('omega.actions.message_public');
        case OmegaAction.SERVER_LOCK: return this.$t('omega.actions.server_lock');
        case OmegaAction.SERVER_UNLOCK: return this.$t('omega.actions.server_unlock');
        case OmegaAction.SERVER_RESTART: return this.$t('omega.actions.server_restart');
        case OmegaAction.SERVER_KICK: return this.$t('omega.actions.server_kick');
        case OmegaAction.SERVER_WIPE: return this.$t('omega.actions.server_wipe');
        case OmegaAction.WHITELIST_PAUSE: return this.$t('omega.actions.whitelist_pause');
        case OmegaAction.WHITELIST_RESUME: return this.$t('omega.actions.whitelist_resume');
        case OmegaAction.GAME_KILL: return this.$t('omega.actions.game_kill');
      }
      return `ERR (${action})`;
    },
    actionHasParameter(action) {
      let unParameterizedActions = [
        OmegaAction.HELP,
        OmegaAction.GAME_KILL,
        OmegaAction.WHITELIST_PAUSE,
        OmegaAction.WHITELIST_RESUME,
        OmegaAction.SERVER_LOCK,
        OmegaAction.SERVER_UNLOCK,
      ];
      return !(unParameterizedActions.includes(action));
    },
    /* ************************** */
    async saveConfiguration() {
      this.inProgress = true;
      await new Promise(resolve => setTimeout(resolve, 500));

      try {
        let commands = [];
        /* Integrity check */
        this.configuration.commands.commands.forEach((command) => {
          if(command.trigger === null || command.trigger === '') return;
          else if(command.action === null) return;
          else commands.push(command);
        });
        this.configuration.commands.commands = commands;
        /* *************** */
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
          configuration: this.configuration
        };
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/commands`, {
          method: 'PATCH',
          body: JSON.stringify(payload),
          credentials: 'include'
        });
        if(response.ok) {
          this.$swal({
            icon: 'success',
            title: this.$t('server.view.configuration.save.success.title'),
            text: this.$t('server.view.configuration.save.success.text'),
          });
          this.configurationChanged = null;
          await this.getConfiguration();
        } else {
          if(response.status === 429) {
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.message'),
              title: this.$t('error.server.ratelimit.title')
            });
          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      }
      this.inProgress = false;
    },
    /* Processing */
    async addElement() {
      this.configuration.commands.commands.push({
        trigger: null,
        action: null,
        parameter: null,
        uuid: this.uuid()
      });
    },
    async removeElement(uuid) {
      let commands = [];
      this.configuration.commands.commands.forEach((command) => {
        if(command.uuid !== uuid) commands.push(command);
      });
      this.configuration.commands.commands = [];
      this.configuration.commands.commands = commands;
    },
    /* ***** */
		scrollHandler() {
			if(!this.$refs.floatingContainer) return;

			if(window.pageYOffset > 70) {
				if(!this.$refs.floatingContainer.classList.contains('c-floating-side')) {
					this.$refs.floatingContainer.classList.add('c-floating-side');
				}
			} else {
				this.$refs.floatingContainer.classList.remove('c-floating-side');
			}
		}
  },
  created() {
    this.server = this.getServer(this.server_id);
  },
  mounted() {
    this.getConfiguration();

	  window.addEventListener('scroll', this.scrollHandler);
  },
	destroyed() {
		window.removeEventListener('scroll', this.scrollHandler);
	},
  watch: {
    configuration: {
      deep: true,
      handler() {
        if(!this.ready) return;
        if(this.configurationChanged === null) {
          this.configurationChanged = false;
        } else this.configurationChanged = true;
      }
    }
  },
  data() {
    return {
      Game:Game,
      OmegaAction:OmegaAction,
      TriggerCondition:TriggerCondition,
      TriggerAttributes:TriggerAttributes,
      StreamEvent:StreamEvent,
      ready: false,
      error: false,
      inProgress: false,
      server: null,
      configurationChanged: null,
      configuration: {

      },
      availableActions: [
        OmegaAction.HELP,
        OmegaAction.RAW,
        OmegaAction.MESSAGE_PRIVATE,
        OmegaAction.MESSAGE_PUBLIC,
        OmegaAction.SERVER_LOCK,
        OmegaAction.SERVER_UNLOCK,
        OmegaAction.SERVER_RESTART,
        OmegaAction.SERVER_KICK,
        OmegaAction.SERVER_WIPE,
        OmegaAction.WHITELIST_PAUSE,
        OmegaAction.WHITELIST_RESUME
      ],
      // Special configs
      special: {

      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-sm-12 d-lg-none">
          <button class="btn btn-block hard-border save-btn"
                  v-on:click="saveConfiguration()"
                  :disabled="inProgress"
                  :class="{
                    'disabled': inProgress,
                    'btn-success': configurationChanged === true,
                    'btn-primary': configurationChanged === false
                  }"
          >
            <half-circle-spinner
                v-if="inProgress"
                :animation-duration="1200"
                :size="16"
                class="align-middle d-inline-block"
            />
            <template v-if="!inProgress">
              {{ $t('server.view.configuration.save.button') }}
            </template>
          </button>
          <div class="text-center small text-muted" v-if="configurationChanged === true">
            {{ $t('server.view.configuration.save.info') }}
          </div>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <button class="btn btn-info btn-block hard-border" v-on:click="addElement()">
            <i class="far fa-plus" />
            {{ $t('server.view.commands.button') }}
          </button>
        </div>
        <div class="col-sm-12 mt-2 d-lg-none">
          <div class="card hard-border ">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
              {{$t('server.view.configuration.placeholders.message')}}
              <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
                <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-sm-12">
          <div>
            <div role="tablist">
              <b-card no-body class="settings-collapse hard-border" v-for="command in configuration.commands.commands" :key="command.uuid">
                <a v-b-toggle="command.uuid" class="text-dark" href="javascript: void(0);">
                  <b-card-header header-tag="header" role="tab">
                    <h3 class="m-0 d-inline-flex">
                      <template v-if="command.action !== null && command.action !== null">
                        <span class="badge badge-primary mr-1" v-if="command.trigger === 'help'">
                          Default
                        </span>
                        <b>!</b>{{ command.trigger }}

                        <small class="ml-2 mr-1 mt-1 hide-sm">
                          <i class="far fa-code-merge font-size-15" />
                          {{ actionToString(command.action) }}
                        </small>
                      </template>
                      <template v-else>
                        <div class="badge badge-warning text-black">
                          {{ $t('server.view.commands.unconfigured') }}
                        </div>
                      </template>
                    </h3>
                    <template v-if="command.trigger !== 'help'">
                      <div class="d-inline-flex float-right">
                        <div class="btn btn-sm btn-dark">
                          {{ $t('server.view.configuration.expand') }}
                        </div>
                      </div>
                      <div class="d-inline-flex float-right mr-2">
                        <div class="btn btn-sm btn-danger" v-on:click="removeElement(command.uuid)">
                          <i class="fal fa-trash-alt" />
                          {{ $t('server.view.configuration.remove') }}
                        </div>
                      </div>
                    </template>
                    <div class="mt-2 d-lg-none">
                      <span>
                        <i class="far fa-code-merge font-size-15" />
                        {{ actionToString(command.action) }}
                      </span>
                    </div>
                  </b-card-header>
                </a>
                <b-collapse :id="command.uuid" accordion="commands" role="tabpanel">
                  <b-card-body>
                    <b-card-text>
                      <div class="row">
                        <div class="col-lg-6 col-sm-12">
                          <h4>{{ $t('server.view.commands.trigger.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col">
                              <b-input v-model="command.trigger" :readonly="command.trigger === 'help'" size="lg" type="text" class="form-control" />
                              <div class="text-center">
                                {{ $t('server.view.commands.trigger.description') }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-12 sm-spacer" v-if="command.trigger !== 'help'">
                          <h4>{{ $t('server.view.commands.action.label') }}</h4>
                          <hr>
                          <div class="row">
                            <div class="col-lg-12 col-sm-12">
                              <multiselect
                                  v-model="command.action"
                                  :options="availableActions"
                                  :multiple="false"
                                  :close-on-select="true"
                                  :clear-on-select="false"
                                  :preserve-search="true"
                                  :placeholder="$t('server.view.commands.action.placeholder')"
                                  :preselect-first="false"
                                  :taggable="false"
                              >
                                <template
                                    slot="singleLabel"
                                    slot-scope="props"
                                >
                                  <div>
                                    <span class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ actionToString(props.option) }}
                                      </span>
                                    </span>
                                  </div>
                                </template>
                                <template
                                    slot="option"
                                    slot-scope="props"
                                >
                                  <div>
                                    <div class="option__desc d-inline ml-2">
                                      <span class="option__title align-middle h5">
                                        {{ actionToString(props.option) }}
                                      </span>
                                    </div>
                                  </div>
                                </template>
                              </multiselect>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="command.action && actionHasParameter(command.action)">
                        <div class="row mt-3">
                          <div class="col-lg-12 col-sm-12">
                            <h4>{{ $t('server.view.commands.parameter.label') }}</h4>
                            <hr>
                            <div class="row">
                              <div class="col">
                                <b-input v-model="command.parameter" size="lg" type="text" class="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!---------------------------------------->
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-sm-12 hide-sm">
	        <div class="" ref="floatingContainer">
	          <button class="btn btn-block hard-border save-btn"
	                  v-on:click="saveConfiguration()"
	                  :disabled="inProgress"
	                  :class="{
	                    'disabled': inProgress,
	                    'btn-success': configurationChanged === true,
	                    'btn-primary': configurationChanged === false
	                  }"
	          >
	            <half-circle-spinner
	                v-if="inProgress"
	                :animation-duration="1200"
	                :size="16"
	                class="align-middle d-inline-block"
	            />
	            <template v-if="!inProgress">
	              {{ $t('server.view.configuration.save.button') }}
	            </template>
	          </button>
	          <div class="text-center small text-muted" v-if="configurationChanged === true">
	            {{ $t('server.view.configuration.save.info') }}
	          </div>

	          <button class="btn btn-info btn-block hard-border mt-2" v-on:click="addElement()">
	            <i class="far fa-plus" />
	            {{ $t('server.view.commands.button') }}
	          </button>

	          <div class="row mt-2">
	            <div class="col-lg-12">
	              <div class="card">
	                <div class="card-body">
	                  <h5 class="text-uppercase">{{$t('server.view.configuration.placeholders.title')}}</h5>
	                  {{$t('server.view.configuration.placeholders.message')}}
	                  <a href="https://docs.cftools.cloud/en/placeholders" target="_blank" class="btn btn-light btn-block mt-4">
	                    <i class="fad fa-external-link"></i> {{$t('server.view.configuration.placeholders.link')}}
	                  </a>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
